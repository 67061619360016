import React from 'react'

import { StaticQuery, graphql } from "gatsby"
import * as ReactGA from "react-ga"

import { Row } from '../components/containers/Layout'
import { TextInput } from '../components/base/Forms'
import {
  Heading,
  Paragraph,
} from '../components/base/Typography'

import Button from '../components/base/Button'

const STATUS = {
  NONE: null,
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error'
}

export class RequestDemoForm extends React.Component {
  static defaultProps = {
    requiredFields: ['name', 'email', 'message'],
    buttonText: 'Book A Demo',
    heading: 'Learn More',
    paragraph: 'Contact us to learn more about how our technology can improve your business.'
  }

  constructor(props) {
    super(props)

    this.state = {
      status: STATUS.NONE,
      validationMessage: '',
      touched: {},
      errors: {}
    }

    // need to use refs instad of onChange because onChange isn't called on
    // autofilled inputs
    this.ref= {
      name: React.createRef(),
      email: React.createRef(),
      company: React.createRef(),
      message: React.createRef()
    }
  }

  get values() {
    return {
      name: this.ref.name.current.value || '',
      email: this.ref.email.current.value || '',
      company: this.ref.company.current.value || '',
      message: this.ref.message.current.value || '',
      products: this.props.product ? [this.props.product] : null
    }
  }

  submit = async (e, url) => {
    e.preventDefault()
    if (!this.validate()) {
      this.setState({
        status: STATUS.NONE
      })
      return
    }

    this.setState({
      status: STATUS.PENDING,
      validationMessage: 'loading...'
    })

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.values)
    })

    if (response.status >= 400) {
      this.setState({
        status: STATUS.ERROR,
        validationMessage:
          "We couldn't send your request, you can try" +
            ' reaching us at info@bite.ai'
      })
      return
    }

    const content = await response.json()

    if (content.status === 'ERROR') {
      this.setState({
        status: STATUS.ERROR,
        validationMessage:
          "We couldn't send your request, you can try" +
            ' reaching us at info@bite.ai'
      })
    } else {
      ReactGA.event({
        category: 'Contact Form',
        action: 'Submit',
        label:`${this.props.product ? this.props.product : 'No Product Selected'}`
      })
      this.setState({
        status: STATUS.SUCCESS,
        validationMessage: "Thanks! We'll contact you soon"
      })
    }
  }

  updateInput = e => {
    this.setState({ inputs: { [e.target.name]: e.target.value } })
  }

  handleInteraction = e => {
    const touched = { ...this.state.touched, [e.target.name]: true }
    this.setState({ touched })
    this.validate(Object.keys(touched))
  }

  validate = (fields = Object.keys(this.ref)) => {
    const missingFields = fields.filter(
      field =>
        this.props.requiredFields.includes(field) &&
        !this.ref[field].current.value
    )
    if (missingFields.length) {
      this.setState({
        errors: missingFields.reduce((x, field) => {
          x[field] = true
          return x
        }, {}),
        validationMessage: `Please include your ${missingFields.join(', ')}`
      })
      return false
    }

    this.setState({
      errors: {},
      validationMessage: ''
    })
    return true
  }

  render() {
return null;

    return (
        <StaticQuery
            query={graphql`
             query HeadingQuery {
               site {
                 siteMetadata {
                   requestFormURL
                 }
               }
             }
           `}
            render={data => (
                <form
                    action={data.site.siteMetadata.requestFormURL}
                    method={'post'}
                    onSubmit={(e) => this.submit(e, data.site.siteMetadata.requestFormURL)}
                    style={{maxWidth: 500, flex: 1}}
                    noValidate={true}>
                  <Heading>{this.props.heading}</Heading>
                  <Paragraph>
                    {this.props.paragraph}
                  </Paragraph>

                  <TextInput
                      required
                      error={this.state.errors.name}
                      ref={this.ref.name}
                      name={'name'}
                      placeholder={'name'}
                      autoComplete={'name'}
                      onBlur={this.handleInteraction}
                      onChange={this.handleInteraction}
                  />
                  <TextInput
                      required
                      error={this.state.errors.email}
                      ref={this.ref.email}
                      name={'email'}
                      placeholder={'email'}
                      autoComplete={'email'}
                      onBlur={this.handleInteraction}
                      onChange={this.handleInteraction}
                  />
                  <TextInput
                      name={'company'}
                      error={this.state.errors.company}
                      ref={this.ref.company}
                      placeholder={'company'}
                      autoComplete={'organization'}
                      onBlur={this.handleInteraction}
                      onChange={this.handleInteraction}
                  />
                  <TextInput
                      ref={this.ref.message}
                      error={this.state.errors.message}
                      name={'message'}
                      multiline
                      rows={5}
                      placeholder={'message'}
                      onBlur={this.handleInteraction}
                      onChange={this.handleInteraction}
                  />
                  <Row style={{padding: '.1rem'}}>
                    <Paragraph
                        style={{
                          textAlign: 'center',
                          display: this.state.validationMessage.length
                              ? undefined
                              : 'hidden'
                        }}>
                      {this.state.validationMessage && this.state.validationMessage.length
                          ? this.state.validationMessage
                          : '.'}
                    </Paragraph>
                  </Row>
                  <Row style={{padding: 0}}>
                    <Button size={'large'} type={'submit'} style={{flex: 1, margin: 0}}>
                      {this.props.buttonText}
                    </Button>
                  </Row>
                </form>
            )}
        />)
  }
}